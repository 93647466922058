(function($) {
    // Add the "active" class to the proximity dropdown if the URL contains the _proximity parameter
    $(document).ready(function() {
        $('.nav-search-btn-on').on('click', function(e) {
            // Toggle the class on the target element
            if (window.innerWidth > 960) {
                e.preventDefault();
                $('.search-drop-down').toggleClass('disabled');
            }
        });

        // Stop click through to Job Page on Enter key press of navigation search button
        $('.nav-search-btn-on').on('keydown', function(e) {
            // Check if the Enter key (key code 13) is pressed
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        });
        // Stop click through to Job Page on Enter key press of autocomplete field
        $('.autocomplete').on('keydown', function(e) {
            // Check if the Enter key (key code 13) is pressed
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        });
    });

}(jQuery));