import Glide from '@glidejs/glide'
(function($) {

    $(document).ready(function() {
        // add sticky to header and jobs search bar on desktop only
        $(window).scroll(function() {
            if (window.innerWidth > 1200) {
                var scrollPosition = $(window).scrollTop();

                // Adjust the value below to determine when the CSS class should be added
                var scrollThreshold = 110;
                var scrollThreshold2 = 502;
                //console.log("Scroll position:", scrollPosition); // Log the scroll position in the console

                if (scrollPosition > scrollThreshold) {
                    // Add the CSS class to your desired element
                    $('.page-head').addClass('sticky');
                    $('.shift-page-content').addClass('sticky');

                } else {
                    // Remove the CSS class if the scroll position is below the threshold
                    $('.page-head').removeClass('sticky');
                    $('.shift-page-content').removeClass('sticky');

                }
                if ($(".page-content-banner .job-search__outer").length) {
                    if (scrollPosition > scrollThreshold2) {
                        // Add the CSS class to your desired element
                        $('.job-search__outer').addClass('sticky');
                        $('.shift-page-content').addClass('sticky-er');


                    } else {
                        // Remove the CSS class if the scroll position is below the threshold
                        $('.job-search__outer').removeClass('sticky');
                        $('.shift-page-content').removeClass('sticky-er');

                    }
                }
            }
        });
        

        // Hide dropdown mega menu on click outside
        $(document).on("click", function(event){
            if(!$(event.target).closest(".js-toggle-submenu__link").length && !$(event.target).closest(".mega-menu").length){
                $(".primary-nav__item--active").siblings('.primary-nav__item--active')
                .removeClass('primary-nav__item--active')
                .find('.mega-menu')
                .slideUp('fast');
    
            $(".primary-nav__item--active").toggleClass('primary-nav__item--active')
                .find('.mega-menu')
                .slideToggle('fast');
                $(".mega-menu").slideUp("fast");
                
            }
        });



        const pagers = document.querySelectorAll("a.facetwp-page");
        
        pagers.forEach((pager) => {
            pager.removeAttribute('tabindex');
            pager.setAttribute('title', pager.innerText);
        });

        const miSelects = document.querySelectorAll("select.facetwp-radius-dropdown");

        miSelects.forEach((miSelect) => {
            miSelect.setAttribute('role', 'select');
        });

        // collapsible accordians on job pages on mobile
        const accordionHeadings = document.querySelectorAll(".heading.accordion");

        accordionHeadings.forEach((accordionHeading) => {
            accordionHeading.addEventListener("click", () => {
                if (accordionHeading.classList.contains("active")) {
                    accordionHeading.classList.remove("active");
                } else {
                    const accordionHeadingsWithIsOpen = document.querySelectorAll("._active");
                    accordionHeadingsWithIsOpen.forEach((accordionHeadingWithIsOpen) => {
                        accordionHeadingWithIsOpen.classList.remove("active");
                    });
                    accordionHeading.classList.add("active");
                }
            });
        });
        // end

        // Expand accordions on mobile for job detail pages when the menu item is clicked
        $('.job-detail-anchors.about-the-role').click( function() {
            $(".accordion.about-the-role").addClass("active");
        } );

        $('.job-detail-anchors.about-you').click( function() {
            $(".accordion.about-you").addClass("active");
        } );

        $('.job-detail-anchors.about-the-partnership').click( function() {
            $(".accordion.about-the-partnership").addClass("active");
        } );

        // collapsible checkbox facets on job search page
        const accordionTitles = document.querySelectorAll(".job-search__filter-title");

        accordionTitles.forEach((accordionTitle) => {
            accordionTitle.addEventListener("click", () => {
                if (accordionTitle.classList.contains("_active")) {
                    accordionTitle.classList.remove("_active");
                } else {
                    const accordionTitlesWithIsOpen = document.querySelectorAll("._active");
                    accordionTitlesWithIsOpen.forEach((accordionTitleWithIsOpen) => {
                        accordionTitleWithIsOpen.classList.remove("_active");
                    });
                    accordionTitle.classList.add("_active");
                }
            });
            $(document).on("click", function(e) {
                if ($(e.target).is(accordionTitle) === false && !$(e.target).closest('.facetwp-type-checkboxes').length && !$(e.target).closest('.material-icons').length) {
                    $(accordionTitle).removeClass("_active");
                }
            });
            $(window).scroll(function() {
                $(accordionTitle).removeClass("_active");
            });
        });
        // end

        // job search block mobile collapsible 
        var coll = document.getElementsByClassName("job-search__mobile-collapse");
        var i;

        for (i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
                var element = document.querySelector('.job-search__mobile-collapse');
                element.classList.toggle("expand");
            });
        }
        // end
        // handle facetwp search button
        $(document).on('click', '.fwp-submit', function() {
            FWP.refresh();
            var href = $(this).attr('data-href');
            var search_string = window.location.search;
            window.location.href = href + search_string;
        });
        // enable enter key search for facetwp
        $(document).keyup(function(event) {
            if (!($("input.search-input").is(":focus"))) {
                var keycode = (event.keyCode ? event.keyCode : event.which);
                if (keycode == '13' && window.location.href.indexOf("/search-jobs") <= -1) {
                    FWP.parseFacets();
                    FWP.setHash();
                    $(".fwp-submit").trigger("click");
                }
            }
        });

        // job search block mobile collapsible 
        var coll = document.getElementsByClassName("fwp-submit-results");
        var element = document.querySelector('.job-search__mobile-collapse');

        for (var i = 0; i < coll.length; i++) {
            coll[i].addEventListener("click", function() {
                if ($(window).width() < 1200) {
                    element.classList.toggle("expand");
                }
            });
        }
        // end


        // Check if #job-search-bar-block exists
        if ($('#job-search-bar-block').length > 0) {
            // If it exists, hide the .right-sidebar element
            $('.right-sidebar').css('display', 'none');
        }

        if ($('#browse-by-role-block').length > 0) {
            // If it exists, hide the .right-sidebar element
            $('.right-sidebar').css('display', 'none');
            // Set the width of .main-content-2-col to 100%
            $('.main-content-2-col').css('width', '100%');
        }

        if ($('#no-result-jobhub').length > 0) {
            // If it exists, hide the .right-sidebar element
            $('.right-sidebar').css('display', 'none');
        }

        // add role none to youtube embed blocks for accessibility
        $(".wp-block-embed-youtube").attr("role", "none");

        // Horizontal draggable scroll for job detail anchor link menu
        const slider = document.querySelector('#page-hero-menu');
        if ( slider !== null ) {
            let mouseDown = false;
            let startX, scrollLeft;
            
            let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            };
            let stopDragging = function (event) {
            mouseDown = false;
            };
            
            slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if(!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
            });
            
            // Add the event listeners
            slider.addEventListener('mousedown', startDragging, false);
            slider.addEventListener('mouseup', stopDragging, false);
            slider.addEventListener('mouseleave', stopDragging, false);
        }

        // Anchor links for job detail pages change color on active
        $('.job-detail-anchors').on('click', function(){
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
        });

        // Store nav links
        var navLinks = $('.job-detail-anchors');

        // Get offset of each link
        var sectionOffsets = {};
        navLinks.each(function() {
            var id = $(this).attr('href');
            sectionOffsets[id] = $(id).offset().top - 200;
        });
        

        // Function to update active class based on scroll position
        function updateActiveSection() {
            var scrollPosition = $(document).scrollTop();
            var windowHeight = $(window).height();
            var documentHeight = $(document).height();

            // Check if user has scrolled to the very bottom of the page
            if (scrollPosition + windowHeight >= documentHeight) {
                // Remove active class from all navigation links
                navLinks.removeClass('active');

                // Add active class to the last navigation link
                navLinks.last().addClass('active');
                return; // Exit the function early if at the bottom
            }
        
            // Calculate the threshold from the bottom of the page
            var threshold = 100; // Adjust this value as needed
        
            // Iterate through section offsets to find the current active section
            $.each(sectionOffsets, function(sectionId, offset) {
                if (
                    scrollPosition >= offset && // Check if scrolled past the section
                    (scrollPosition + windowHeight) < (documentHeight - threshold) // Check if not close to the bottom
                ) {
                    // Remove active class from all navigation links
                    navLinks.removeClass('active');
        
                    // Add active class to the corresponding navigation link
                    $('a[href="' + sectionId + '"]').addClass('active');
                }
            });
        }

        // Initial call to update active section on page load
        updateActiveSection();

        // Listen for scroll events and update active section accordingly
        $(window).scroll(function() {
            updateActiveSection();
        });
        //End scrolling logic


        // define meet our people slider for job detail pages
        if ( $('.meet-our-people-block .glide').length ) {
            var glide = new Glide('.meet-our-people-block .glide', {
                breakpoints: {
                    992: {
                        type: 'carousel',
                        perView: 1,
                    }
                }
              })
              
              glide.mount()
        }

        // define networks slider for job detail pages
        if ( $('.networks-block .glide').length ) {
            var glide = new Glide('.networks-block .glide', {
                type: 'carousel',
                perView: 4,
                breakpoints: {
                    992: {
                        type: 'carousel',
                        perView: 1
                    }
                }
                })
                
                glide.mount()
        }


        // define latest job slider for job detail pages
        if ( $('.latest-job-block .glide').length ) {
            var glide = new Glide('.latest-job-block .glide', {
                type: 'carousel',
                perView: 1
            })
            
            glide.mount()
        }   

        // define benefit slider for job detail pages
        function benefitSlider() {  
            if ( $('.benefits-wrap-mobile').length ) {
                var glide = new Glide('.benefits-wrap-mobile', {
                    type: 'carousel',
                    perView: 1,
                    startAt: 0
                })
                
                glide.mount()
            }
       }
        // call benefit slider on window resize and load
        $(window).on("load resize",function(e){
            benefitSlider();
        });

        const button = document.getElementById("slide");

        if ( button != null ) {
            // handle the right arrow click to scroll horizontally on mobile for the page menu on job detail pages
            button.onclick = () => {
                document.getElementById("page-hero-menu").scrollLeft += 100;
            };
        }

    });

    // Fade in/out for back to top button
    $(document).scroll(function () {
        var y = $(this).scrollTop();
        if (y > 800) {
            $('#footer-to-top').fadeIn();
        } else {
            $('#footer-to-top').fadeOut();
        }
    
    });
    
}(jQuery));