// Import Styles
import '../sass/styles.scss';

import 'regenerator-runtime/runtime';

import '../../../js/phase-two/geo-location.js';
import '../../../js/phase-two/new-index.js';
import '../../../js/phase-two/functional.js';

import './cookies/cookies';
import './sliders/we-saw-you-looking-at';
import './sliders/slick';
import './sliders/slick-functions';

(function() {

    $(document).ready(function() {

    });

    $(window).scroll(function() {

    });

})();