jQuery(document).ready(function($) {

    /**
     * Loop through all slick sliders and set up based on provided data attributes
     */
    if ($('[data-slick]').length) {

        $('[data-slick]').each(function() {
            var options = $(this).data('slick');
            $(this).slick(options);
        });
    }

    $(window).on('resize', function() {
        $('[data-slick]').slick('resize');
	});
});
